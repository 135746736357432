/* ==========================================================================
   Base styles
   ========================================================================== */

$nav_break_point: 1500px;

html,
body {
  height: 100%;
  font-size: 42.5%;
  font-family: 'Open Sans', sans-serif, "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: $grey;
  background-color: rgba($page_bg, 1);

  @media (max-width: $nav_break_point) {
    // font-size: 44.25%;
  }

  @media (max-width: 1024px) {
    font-size: 46.875%; // 75% reduced
  }
}

@media (min-width: 1000px) {
  .myItemCardButton {
    position: relative;
    top: 20%;
  }

}

@media (min-width: 768px) {
  ::-webkit-scrollbar {
    width: 12px;

    /* for vertical scrollbars */
    height: 8px;
    /* for horizontal scrollbars */
  }

  ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
  }

  ::-webkit-scrollbar-thumb {
    background: darken($primary, 5%) 0% 0% no-repeat padding-box;
    max-width: 50px;
    border-radius: 10px;
  }
}

/* radio ,checkbox style */
/* .radio-button {
     input {
       &[type="radio"] {
         position: absolute !important;
         left: -99999px;
       }
   
       &[type="checkbox"]:checked+label:before {
         background: url(../images/checked.svg) right top no-repeat;
       }
   
       &[type="radio"]:checked+label {
         background-color: $primary;
         color: $light;
       }
     }
   } */

/* Typography
      ========================================================================== */

h1 {
  font-weight: bold;
  font-size: 2.8rem;
  // margin-top: 5rem;
}

h2 {
  font-weight: bold;
  font-size: 2rem;
  color: $dark;
}

h3 {
  font-weight: 600;
  font-size: 4.45rem;
}

h4 {
  font-weight: 600;
  font-size: 1.8rem;
  margin-bottom: 0;
}

h5 {
  font-size: 1.8rem;
  //  line-height: 1.5;
}

h6 {
  font-weight: 600;
  font-size: 2.2rem;
  // margin: 1.8rem 0 2.5rem;
  margin-bottom: 1.4rem;
}

//  p {
//    font-weight: normal;
//    font-size: 1.55rem;
//    margin: 0 0 2rem;
//    line-height: 1.7;
//  }

blockquote {
  max-width: 800px;
  font-weight: 300;
  font-size: 2.6rem;
  margin-bottom: 4.5rem;
}

ul {
  // font-weight: 300;
  font-size: 1.8rem;
  letter-spacing: -0.02em;
}

table a,
.summary-third-row a {
  text-decoration: underline;
}


//  .btn {
//    font-weight: 500;
//    font-size: 1.8rem;
//    // padding: 0.75rem 1.4rem;
//  }

.btn-outline-primary {
  transition: all 0.4s ease;
}

.btn-light-blue {
  background-color: #E8F4FF;
  border-color: #E8F4FF;
  color: #70BCFD;
  font-weight: bold;

}


.dropdown-menu {
  right: 0;
  left: auto;
  font-size: 1.4rem;
}

.dropdown-item {
  padding: 0.8rem 1.5rem;
}


.carousel-caption {
  text-align: left;
}


.card {
  font-size: 1.8rem;
  font-weight: normal;
  color: $dark;
  border-color: transparent;
  // box-shadow: 0px 0px 6px #D9D9D989;
  // border-color: #F5F5F5;

  .card-header {
    background: #ffffff;
    font-weight: Bold;
    font-size: 1.6rem;

    >a {
      font-weight: 600;
      font-size: 1.6rem;
      letter-spacing: 0.2em;
      color: #a8a8a8;
      text-decoration: none;
      position: relative;
      padding-bottom: 2rem;
      margin: 0 0.5rem;

      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 3px;
        background-color: transparent;
      }

      &:hover,
      &.active {
        color: $primary;

        &::before {
          background-color: $primary;
        }
      }
    }

    .btn-outline-secondary {
      text-align: left !important;
      border-color: #ced4da;

    }




  }

  .card-body {
    border-radius: 10px;
    // background: #fff;
    box-shadow: 0px 3px 30px rgba(144, 144, 144, 0.1);

    h1 {
      letter-spacing: 0;
    }
  }

  .card-footer {
    // background-color: #fff;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;

  }
}



.table {
  .thead-light {
    th {
      color: #212121;
      background-color: #F5F5F5;
      border-color: #F5F5F5;
      padding-top: 1.2rem;
      padding-bottom: 1.2rem;
    }
  }
}


.badge-danger {
  background-color: rgba($danger, .2);
  border: 1px solid $danger;
  color: $danger;
}

.badge-secondary {
  background-color: rgba($secondary, .2);
  border: 1px solid $secondary;
  color: $secondary;
}

.text-blue {
  color: $blue;

}

.font-weight-semibold {
  font-weight: 600 !important;
}


.bg-light-grey {
  background-color: #F5F5F5 !important;
}

.progress-bar {
  min-width: 20% !important;
}

.progress-bar.pharmacy {
  min-width: 0% !important;
}

.fa-info-circle {
  margin-left: 5px;
}

.item {
  font-size: 1.5rem !important;
}

.p-10 {
  padding: 4em !important
}

.signup-hover:hover {
  size: 24px;
}

.heartoutline {
  background: url("https://cdn.shopify.com/s/files/1/1702/7393/files/heartoutline.png?v=1611615140");
  display: inline-block;
  width: 16px;
  height: 16px;
  color: red;
  background-repeat: no-repeat;
}

.heartfull {
  background: url("https://cdn.shopify.com/s/files/1/1702/7393/files/heartfull.png?v=1611615166");
  display: inline-block;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
}

.index-alignment {
  margin-left: 93px;
}

.index-alignment-nav {
  margin-left: 99px;
}

.card-event-set {
  padding: 0%;
  margin: 0% !important;
  margin-top: 2% !important;
}